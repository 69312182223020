a,
button {
    transition: 0.3s;
    transition-property: color, background-color, border, opacity;
}

.slide-fade-leave-active,
.slide-fade-enter-active {
    transition: all 0.3s ease-out;
}

.slide-fade-enter,
.slide-fade-leave-to {
    opacity: 0;
    transform: translateY(4px);
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s ease;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
