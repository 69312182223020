@layer base {
    body {
        font-size: 100%;
        line-height: 1;
        word-wrap: break-word;
        -ms-text-size-adjust: 100%;
        -webkit-text-size-adjust: 100%;
        text-size-adjust: 100%;
    }

    body,
    button,
    datalist,
    input,
    select,
    textarea {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
    }
}

@font-face { 
    font-family: 'Phonk';
    src: url('/fonts/phonk-regular.otf') format('opentype');
}

@font-face { 
    font-family: 'Cera Pro';
    src: url('/fonts/cera-pro-bold.otf') format('opentype');
    font-weight: bold;
}

@font-face { 
    font-family: 'Cera Pro';
    src: url('/fonts/cera-pro-medium.otf') format('opentype');
    font-weight: 500;
}

body {
    margin: 0;
    position: relative;
}
